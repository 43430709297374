"use client"
import { useCart } from '@/hooks/use-cart'
import Link from 'next/link'
import { Icons } from '@/components/common/icons'

const Cart = () => {
  const { items } = useCart()
  const itemCount = items.reduce((total, { quantity }) => total + quantity, 0)
  return (
    <Link href={"/cart"}>
      <div className='relative'>
        <Icons.cart className='text-gray-800 w-8 h-8' />
        <div className='w-4 h-4 flex items-center justify-center absolute -right-1 top-0 bg-secondary rounded-full'>
          <p className='text-xs font-bold'>{itemCount}</p>
        </div>
      </div>
    </Link>
  )
}

export default Cart

"use client"
import { NAVLINKS } from '@/config'
import { cn } from '@/lib/utils'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import { useCallback, useMemo } from 'react'

const NavItems = () => {
  const pathname = usePathname()
  // console.log(pathname)
  const activeIndex = 
  useMemo(
    () => {
    // console.log("finding index...", pathname)
    const index = NAVLINKS.findIndex((item) => pathname == item.href)
    // console.log("index.. ", index)
    return index
  }
  , [pathname])
  // console.log("active index..", activeIndex)
  const isActive = useCallback((index: number) => {
    // console.log(index, activeIndex)
    return index == activeIndex
  }, [activeIndex])
  return (
    <nav className="hidden items-center gap-4 md:flex">
      {
        NAVLINKS.map((link, i) => <Link key={link.label} className={cn("text-sm font-medium hover:underline hover:underline-offset-4", {
          'font-bold underline underline-offset-4': isActive(i)
        })} href={link.href}>
          {link.label}
        </Link>)
      }
    </nav>
  )
}

export default NavItems
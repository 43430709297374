
export const LOCATIONS = [
    "Soorangudi","Nagercoil","Kalakad", "Eruvadi", "Vallioor", "Avaraikulam","Kallikulam", "Panagudi", "Kavalkinaru", "Vadakan Kulam","S.N.Pallivasal","Thirukkurungudi","Durai Kudieruppu"
]

export const DELIVERY_DAYS = {"monday": 0, "tuesday": 1, "wednesday": 2, "thursday": 3, "friday": 4, "saturday": 5, "sunday": 6}
export const DELIVERY_TIME_IDX = {"morning": 0, "afternoon": 1, "evening": 2}

export const LOCATION_PINCODE = [
    '627103',
    '627105',
    '627107',
    '627108',
    '627109',
    '627112',
    '627116',
    '627117',
    '627501',
    '627502',
    '629001',
]

export const LOCATION_SCHEDULES = [
    {day: "Monday", morning: "Kavalkinaru - 627105, Nagercoil - 629001,629002,629003,", afternoon: "Kalakad - 627501"},
    {day: "Tuesday", morning: "Nagercoil - 629001,629002,629003,", afternoon: "Vallioor - 627117"},
    {day: "Wednesday", morning: "Vadakankulam - 627116, Nagercoil - 629001,629002,629003,", afternoon: "Thirukkurungudi - 627115"},
    {day: "Thursday", morning: "Panagudi - 627109, Nagercoil - 629001,629002,629003,", afternoon: "Vallioor - 627117"},
    {day: "Friday", morning: "T. Kallikulam - 627113, Nagercoil - 629001,629002,629003, Durai Kudieruppu - 627112,", afternoon: "Vallioor - 627117"},
    {day: "Saturday", morning: "Mavadi - 627107, Panagudi - 627109, Nagercoil - 629001,629002,629003,", afternoon: "Eruvadi - 627108"},
    {day: "Sunday", morning: "Avaraikulam - 627105, Nagercoil - 629001,629002,629003,", afternoon: "Unavailable"},
]


import { HERO_IMAGES } from "@/constants";


export const NAVLINKS = [
  {
    label: "Home",
    href: "/"
  },{
    label: "Shop",
    href: "/products"
  },{
    label: "Our Story",
    href: "/our-story"
  },{
    label: "Contact",
    href: "/contact"
  },
]
export const PRODUCT_CATEGORIES = [
  {
    label: "Mushrooms",
    value: "mushrooms" as const,
    featured: [{
      name: "Oyster Mushroom",
      href:"#",
      imageSrc: HERO_IMAGES[0].sm
    },
    {
      name: "Button Mushroom",
      href:"#",
      imageSrc: HERO_IMAGES[1].sm
    }]
  },

  {
    label: "Health Mix",
    value: "health_mix" as const,
    featured: [{
      name: "Shroomix",
      href:"#",
      imageSrc: HERO_IMAGES[0].sm
    }]
  },
  
]

export const FOOTER_QUICK_LINKS = [{
  label: "Home",
  href: "/"
}, {
  label: "Shop",
  href: "/products"
},{
  label: "About",
  href: "/our-story"
},{
  label: "Contact",
  href: "/contact"
},

]

export const FOOTER_ADDITIONAL_LINKS = [{
  label: "Privacy Policy",
  href: "/privacy-policy"
}, {
  label: "Terms & Conditions",
  href: "/terms-and-conditions"
},{
  label: "Return Policy",
  href: "/return-policy"
},{
  label: "Shipping & Delivery",
  href: "/shipping-policy"
},

]

export const MILESTONES = [
  {
    "year": 2022,
    "title": "Skill Development and Workshop Attendance",
    "description": "In 2022, we focused on learning the necessary skills for setting up the mushroom farm. We attended various workshops and training sessions to enhance our knowledge and expertise in farm setup and mushroom cultivation."
  },
  {
    "year": 2023,
    "title": "Farm Setup and Initial Sales",
    "description": "By 2023, we successfully set up the mushroom farm and commenced production. We started selling a small quantity of mushrooms, laying the foundation for our business."
  },
  {
    "year": 2024,
    "title": "Expansion and Increased Sales",
    "description": "In 2024, our mushroom farm achieved significant growth. We are now selling 20 kilograms of mushrooms per day, marking a substantial increase in production and sales compared to previous years."
  }
]
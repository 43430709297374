import {Organization, MerchantReturnEnumeration, WithContext} from 'schema-dts'
/** Home */

export const HOME_SEO_TITLE = "KP Kaalan | Fresh Kaalan! Fair Price!";
export const HOME_SEO_DESCRIPTION =
  "Discover the exceptional freshness and quality of our farm-fresh kaalan (mushrooms) at [KP Kaalan Delight in a variety of gourmet kaalan (mushrooms) cultivated sustainably, handpicked for perfection. Explore our commitment to organic practices and immerse yourself in the rich flavors of Oyster and Button kaalan (mushrooms). Elevate your culinary experience with our premium, locally grown fungi. Experience nature's bounty, straight from our farm to your table. Cultivate a connection with wholesome, sustainable living through KP Kaalan.";
export const HOME_SEO_TAGS =
  "kpkaalan, kp kaalan, kaalan, mushroom, mushroom sale, mushroom farm, farm, mushroom production, agriculture, mushroom agriculture, mushroom sell, kaalan production, kp, kp mushroom, kpmushroom, oyster mushrooms, button mushrooms, farm fresh, mushroom harvesting, Soorangudi Mushrooms, KP Kaalan Organic Farm, Fresh, Local Mushroom TamilNadu, Mushroom Recipes, Cooking with Oyester Mushroom, Cooking with button Mushroom, Fresh Kaalan Fair Price";

/** Products */

export const PRODUCTS_SEO_TITLE =
  "KP Kaalan - Explore Premium Mushroom Varieties | Fresh and Organic Mushrooms";
export const PRODUCTS_SEO_DESCRIPTION =
  "Indulge in the exceptional quality of our gourmet mushrooms at KP Kaalan. Discover a variety of fresh and organic Oyster and Button mushrooms, cultivated sustainably for a rich culinary experience. Elevate your dishes with our premium mushroom varieties straight from our farm to your table.";
export const PRODUCTS_SEO_TAGS =
  "Organic Mushrooms, Gourmet Fungi, Fresh Mushroom Selection, Sustainable Mushroom Farming, Locally Grown Mushrooms, Premium Mushroom Varieties, Culinary Mushrooms, Farm-to-Table Mushrooms, Oyster Mushrooms, Button Mushrooms, Cooking with Mushrooms, Wholesome Mushroom Choices, Healthy Mushroom Recipes, Mushroom Harvesting, KP Kaalan Products, Farm Fresh Mushroom Delights, Mushroom Varieties for Sale, Mushroom Farm to Plate Experience, Gourmet Mushroom Collection, Fresh and Local Mushroom Supply " + HOME_SEO_TITLE;

/** Terms and Condition */
export const TERMS_SEO_TITLE ='Terms and Conditions | K P Kaalan'
export const TERMS_SEO_DESCRIPTION= "These terms and conditions outline the rules and regulations for the use of K P Kaalan's Website"
  
/** Privacy Policy */
export const PRIVACY_SEO_TITLE ='Privacy Policy | K P Kaalan'
export const PRIVACY_SEO_DESCRIPTION= "This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the ..."
 
/** REturn Policy */
export const RETURN_SEO_TITLE ='Return & Refund Policy | K P Kaalan'
export const RETURN_SEO_DESCRIPTION= "Return & Refund Policy describes return and refund policy of K P Kaalan"
  
/** SHIPPING Policy */
export const DELIVERY_SEO_TITLE ='Shipping & Delivery Policy | K P Kaalan'
export const DELIVERY_SEO_DESCRIPTION= "Shipping & Delivery Policy describes shipping and delivery policy of K P Kaalan"
  

/** COntact */
export const CONTACT_SEO_TITLE =
  "Get in Touch with KP Kaalan | Contact Us Today";
export const CONTACT_SEO_DESCRIPTION =
  "Connect with us at KP Kaalan. Whether you have inquiries about our premium mushrooms, want to visit the farm, or explore partnership opportunities, our team is here to assist. Reach out to experience the freshest and finest Oyster and Button mushrooms delivered straight from our sustainable farm.";
export const CONTACT_SEO_TAGS="Contact Us, KP Kaalan, Farm Visit Inquiry, Mushroom Inquiries, Partnership Opportunities, Fresh Mushroom Supplier, Sustainable Farming Contact, Oyster Mushrooms Contact, Button Mushrooms Inquiry, Farm-to-Table Connection, Mushroom Farm Collaboration, Culinary Mushroom Contact, soorangudi Mushroom Farm, Gourmet Mushroom Questions, Mushroom Delivery Inquiries, Wholesale Mushroom Contact, Fresh Produce Partnership, Mushroom Enthusiast Contact, Farm Sustainability Inquiry, Local Mushroom Farm Contact," + HOME_SEO_TITLE 

/** Common */

export const WEBSITE_URL = "https://kpkaalan.com";

export const SITE_NAME = "kpkaalan";

export const CREATOR = "kpkaalan";

export const TWITTER_SITE = "@kpkaalan";

export const TWITTER_CARD = "summary_large_image";

export const CATEGORY = "website";

export const GOOGLE_VERIFICATION =
  "Ld85k70O-dMDjTMPGngXeV3ZPgVS50snMly1db2HqAY";


export const BUSINESS_PROFILE_JSON_LD: WithContext<Organization> = {
  "@context": "https://schema.org",
  "@type": "OnlineStore",
  "image": [
    "https://media.graphassets.com/JkcvDHHR7qw1gFCixgsA",
    "https://media.graphassets.com/rcumpEp6Q66EqbugrH0t",
   ],
  "name": "K P Kaalan",
  "address": {
    "@type": "PostalAddress",
    "streetAddress": "66, Amman Kovil Street Soorangudi Nadar Kudieruppu, Nanguneri, Tirunelveli, TamilNadu - 627108",
    "addressLocality": "Soorangudi",
    "addressRegion": "Tamil Nadu",
    "postalCode": "627108",
    "addressCountry": "IN"
  },
  "url": "https://kpkaalan.com",
  logo: "https://media.graphassets.com/JkcvDHHR7qw1gFCixgsA",
  location: {
    "@type": "Place",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "66, Amman Kovil Street Soorangudi Nadar Kudieruppu, Nanguneri, Tirunelveli, TamilNadu - 627108",
      "addressLocality": "Soorangudi",
      "addressRegion": "Tamil Nadu",
      "postalCode": "627108",
      "addressCountry": "IN"
    },
    "geo": {
      "@type": "GeoCoordinates",
      "latitude": 8.497348,
      "longitude": 77.609333
    },
    "name": "K P Kaalan",
  },
  contactPoint:{  
    "@type": "ContactPoint",
    "telephone": "+919626517144",
    "contactType": "Enquiry/Orders",
    "email": "kpkaalan@gmail.com",
  },
  email:"kpkaalan@gmail.com",
  "telephone": "+919626517144",
  areaServed:"Tamil Nadu",
  description:"",
  founder:"Bala Ganesh",
  foundingLocation:"Tamil Nadu",
  foundingDate:"2022",
  keywords: "kpkaalan, kp kaalan, kaalan, mushroom, mushroom sale, mushroom farm, farm, mushroom production, agriculture, mushroom agriculture, mushroom sell, kaalan production, kp, kp mushroom, kpmushroom, oyster mushrooms, button mushrooms, farm fresh, mushroom harvesting, Soorangudi Mushrooms, KP Kaalan Organic Farm, Fresh, Local Mushroom TamilNadu, Mushroom Recipes, Cooking with Oyester Mushroom, Cooking with button Mushroom, Fresh Kaalan Fair Price",
  hasMerchantReturnPolicy: {
    "@type":"MerchantReturnPolicy",
    returnPolicyCategory: 'MerchantReturnNotPermitted',
    returnPolicyCountry:"IN",
    applicableCountry:"IN",
  },
}